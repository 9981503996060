<template>
  <b-dropdown variant="link" toggle-class="p-0" no-caret right menu-class="w-100">
    <template #button-content>
      <div class="picker-detail">
        <div class="d-flex justify-content-between" >
          <div>
            <span class="items-selected" :class="colorText">Elija una opción</span>
            <div class="items-selected-count" :class="colorText"> <b>{{experiencesDetails.reduce((sum, li) => sum + li.qty, 0)}} </b> Seleccionados </div>
          </div>
          <div class="arrow-picker">
            <feather-icon icon="ChevronDownIcon" size="20" />
          </div>
        </div>
      </div>
    </template>

		<!-- tipo que es diferente a 3, no es grupo -->
		<div v-if="experiencesDetails[0].type !== 3">
      <b-dropdown-form class="content-detail">
        <div v-for="(detail) in experiencesDetails" :key="detail.experienceContractRateId">
          <div>
            <span class="name-detail" :class="colorText">{{detail.typeDisplay}}</span>
          </div>
          <div class="d-flex justify-content-between">
            <div class="price-detail">
              <span variant="warning" :class="colorText"> ${{detail.amount}} USD</span>
            </div>

            <div>
              <button type="button" class="minus-qty" :disabled="1" @click="handlerQty('minus', detail)">-</button>
              <span class="qty-number" :class="colorText">{{ detail.qty }}</span>
              <button type="button" class="plus-qty" :disabled="1" @click="handlerQty('plus', detail)"> + </button>
            </div>
          </div>
        </div>
      </b-dropdown-form>

      <div class="footer-dropdown">
        <div>
          <b v-if="product.total !=='0.00'" class="total-product"> Total: $ {{product.total}}  USD</b>
        </div>
        <div>
          <b-button variant="primary"  @click="handlerAddToCart(product)" size="sm">
            <feather-icon icon="ShoppingCartIcon" size="16" />
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="experiencesDetails[0].type === 3 ">
      <b-dropdown-form class="content-detail">
        <div v-for="(detail) in experiencesDetails" :key="detail.experienceContractRateId">
          <div class="pax-group">
            <div>
              <p>Pax</p>
              <span>{{ detail.num }}</span>
            </div>
            <b class="total-product"> Total: $ {{detail.total}}  USD</b>
          </div>
          <div class="btn-cart-drop">
            <b-button variant="primary" @click="handlerAddToCart(product)" size="sm">
              <feather-icon icon="ShoppingCartIcon" size="16" />
            </b-button>
          </div>
        </div>
      </b-dropdown-form>
    </div>
  </b-dropdown>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { market } from '@/modules/shop/mixins/market'
import { toJson } from '@/helpers/helpers'

export default {
  mixins: [ market ],
  props:{
    experiencesDetails: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    isSingle:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    ...mapState('appConfig',['layout']),
    colorText(){
      return this.layout.skin === 'dark' ? 'color-text-dark' : 'color-text-light'
    },
  },
  methods:{
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations('shop',['addProductInCart','addProductsFromPackage']),
    ...mapMutations('products',['resetQtyProductDetail']),
    handlerQty(operator, detail){
      if(operator === 'plus'){
        detail.qty += 1
      }
      if(operator === 'minus'){
        detail.qty -= 1
      }
      if(this.isSingle){
        this.handlerQtyProductSingle(this.product, detail)
      }

      if( !this.isSingle ){
        this.handlerQtyProduct(this.product, detail)
      }
    },
    async handlerAddToCart(product){
      let item = toJson({...product})//spread
      const productsAddCart = this.generateProductsToAddCart(item) // desde el mixin
      //ciclo asyncrono para ir añadiendo al cart
      productsAddCart.forEach( async productCart => {
        const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
        const productIsRepeated = this.existsInCart( productFormatted )

        if( !productIsRepeated ){ // si producto no se repite
          await this.manageAddProductInCart(productFormatted, productFormatted.categoryName) // agrego el producto formateado
        } else {
          await this.addOneMoreQty(productFormatted)
        }
      })

      if( product.modelType != 'rates'){
        this.resetQtyProductDetail( { idProduct:product.id } ) // reseteo los qty detail del producto
       }

    },
    async addOneMoreQty(product){
      const { modelType, detailId, qty }  = product
      const prodInCart = this.cart.items.find( product => product.detailId === detailId && product.modelType === modelType )
      prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
      await this.calculateTotalWithDiscounts()
      await this.manageAddProductInCart(prodInCart, product.categoryName)
      await this.qtyHasChanged(prodInCart) // desde el mixim market
    },
  }
}
</script>

<style scoped>
.picker-detail{
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  cursor: pointer;
  padding: .5rem 1rem;
}

.picker-detail:active, .picker-detail:focus {
  border: 2px solid black;
  color: black;

}
.items-selected {
  font-size: 11px;
  font-weight: 800;
  margin-block-end: .5rem;
  display: flex;
  align-items: left;
  color: black;

}
.items-selected-count{
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: left;
  color: black;
}
.items-selected-count b {
  margin-inline-end: .4rem;
}
.arrow-picker{
  display: flex;
  align-items: center;
}
.dropdown.b-dropdown{
  width: 100%;
}

.minus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  margin-inline-end: .5rem;
  color: black;
  font-size: 1.5rem;
}
.minus-qty:disabled{
  cursor: not-allowed;
}
.plus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  color: black;
  font-size: 1.5rem;
}
.plus-qty:disabled{
  cursor: not-allowed;
}
.minus-qty:hover, .plus-qty:hover{
  border: 1px solid #030303;

}
.qty-number{
  font-weight: 600;
  margin-inline-end: .5rem;
  color: black;
}
.name-detail{
  font-size: .9rem;
  font-weight: 600;
  color: black;
}
.price-detail{
  font-size: .8rem;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
}
.color-text-light{
  color: black!important;
}
.color-text-dark{
  color: white!important;
}
.footer-dropdown{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 1rem;
  padding: 1rem;
}

.total-product {
  color: rgb(255, 254, 254);
  font-size: .8rem;
  background-color: #F56960;
  padding: 0.3rem 0.6rem;
  text-align: center;
  font-weight: 500;
}

.content-detail{
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.pax-group{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.pax-group p{
	margin: 0;
	color: black;
	font-weight: bold;
}

.pax-group span {
	font-size: .8rem;
  font-weight: 500;
  color: black;
}

.btn-cart-drop{
	display: flex;
	justify-content: end;
}
.content-detail::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar{
  width: 10px;
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar-thumb {
  background-color: #333366;
  background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

</style>