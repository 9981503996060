<template>
  <div class="img-exp">
    <div v-if="payloadParamsRates">  
      <b-link v-if="product.modelType == 'rates'"
        :to="{ name: 'experience-details', params: { slug: product.id +'_'+ payloadParamsRates.dateRange +'_'+ payloadParamsRates.adults +'_'+ payloadParamsRates.children  } }"
      >    
        <img :src="imgUrlExperience + product.coverImage" :alt="`${product.name}-${product.id}`" @error="imgAlternativo" height="200" width="100%" style="cursor: pointer">
      </b-link>

        <b-link v-if="product.modelType == 'product'"
        :to="{ name: 'sales-product-details', params: { slug: 'products-'+ product.id } }"
      >    
        <img :src="imgUrl + product.coverImage" :alt="`${product.name}-${product.id}`" @error="imgAlternativo" height="200" width="100%" style="cursor: pointer">
      </b-link>

      <b-link v-if="product.modelType == 'event'"
        :to="{ name: 'sales-product-details', params: { slug: 'events-'+ product.id } }"
      >
        <img :src="imgUrl + product.coverImage" :alt="`${product.name}-${product.id}`" @error="imgAlternativo" height="200" width="100%" style="cursor: pointer">
      </b-link>
      <b-link v-if="product.modelType == 'package'"
        :to="{ name: 'sales-product-details', params: { slug: 'packages-'+ product.id } }"
      >
        <img :src="imgUrl + product.coverImage" :alt="`${product.name}-${product.id}`" @error="imgAlternativo" height="200" width="100%" style="cursor: pointer">
      </b-link>    
      
    </div>
  </div>
</template>

<script>
 
export default {
  props:{
    product: {
      type: Object,
      required: true
    },
    payloadParamsRates: {
      type: Object,
      default: null
    },
  }, 
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imgUrlExperience: process.env.VUE_APP_IMGEXP_SRC_API,
      imdDefault: require('@/assets/images/default.jpg')
      // imdDefault: 'https://picsum.photos/seed/picsum/200/300'      
    }
  },
  methods:{
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    showProduct(product){      
      this.$router.push({ name: 'sales-product-details', params: { slug: 'products-'+ product.id } })
    },
  }
}
</script>


<style>
.card-img-top {    
    height: 10rem;
    object-fit: cover;
}
.img-exp{
  height: 200px;
}
</style>