<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="filters.currentPage"
          :total-rows="totalRows"
          :per-page="filters.perPage"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </section>  
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    }    
  }  
}
</script>


