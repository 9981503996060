<template>
    <div class="mt-1">
        <b-button variant="warning" block size="sm" v-if="productsExistInCart > 0" :to="{ name: 'order-summary'}">            
            <feather-icon icon="ShoppingCartIcon" size="16" /> Ver en carrito
        </b-button> 
    </div>
</template>
<script>
import {  mapState } from 'vuex'
export default {
  props:{           
    product: {
      type: Object,
      required: true,
    },
    
  },
  computed:{
    ...mapState('shop',['cart']),
    productsExistInCart(){ //si existe algun producto detalle en el cart
    
        const idProductsCart = this.cart.items.map( item => item.detailId ) //obtengo id de detalle en el cart, genero un arreglo de ids
        const result = this.product.detail.filter(({id}) => idProductsCart.includes(id))
        return result.length
    }
  },
  methods:{
    
   
  }
}
</script>