<template>
  <div class="sidebar-detached sidebar-left " style="z-index: 100;">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
      
        <b-card>
          <div class="text-center" v-if="isLoadingCategories">
            <b-spinner label="Loading..." variant="success"/><br>
            <strong>Cargando Categorías </strong>
          </div>
          <div v-if="!isLoadingCategories && selectedCategory">
                    
             
    
      <FiltroSidebarBooking
         @sendQuote="sendEXperience"
      />

  <div v-if="categories.hotelServices.length" class="multi-range-price">
              <h6 class="mt-0" :class="colorFilter">
              <feather-icon
                  icon="HomeIcon"
                  size="16"
                /> Servicios hoteleros
              </h6>
              <b-form-radio-group
                name="cats"
                class="categories-radio-group ml-1"
                stacked
                v-model="selectedCategory.value"
                :options="categories.hotelServices"
                @change="getProducts"
                :disabled="isloadingProducts"
              />
            </div>
            <app-collapse v-if="categories.hotelServicesWithSubCategories.length">
              <app-collapse-item v-for="(category, index) in categories.hotelServicesWithSubCategories" :key="index" :isVisible="collapseOpen( category)" :title="category.text" >
                <template v-slot:header>
                  <div class="d-flex justify-content-between">
                    <!-- <span class="mr-1"> <feather-icon icon="ArrowDownCircleIcon" size="16" /></span> -->
                    <b :class="colorFilter">{{category.text}} </b>
                  </div>
                </template>
                <b-form-radio-group
                  name="cats"
                  class="categories-radio-group "
                  stacked
                  :options="category.subcategory"
                  v-model="selectedCategory.idSubCategory"
                  v-if="selectedCategory"
                  :disabled="isloadingProducts"
                  @change="getProductsBySubCategory"

                />
              </app-collapse-item>
            </app-collapse>
            <div v-if="categories.onlyCategories.length" class="brands">
              <h6 class="filter-title" :class="colorFilter">
                <feather-icon
                  icon="ListIcon"
                  size="16"
                />  Categorías
              </h6>

              <b-form-radio-group
                name="cats"
                class="brands-radio-group ml-1"
                stacked
                v-model="selectedCategory.value"
                :disabled="isloadingProducts"
                :options="categories.onlyCategories"
                @change="getProducts"
              />
            </div>
            <app-collapse v-if="categories.withSubCategories.length">
              <app-collapse-item :title="category.text" v-for="(category, index) in categories.withSubCategories" :key="index" :isVisible="collapseOpen( category)">
                <template v-slot:header>
                  <div class="d-flex justify-content-between">
                    <!-- <span class="mr-1"> <feather-icon icon="ArrowDownCircleIcon" size="16" /></span> -->
                    <b :class="colorFilter">{{category.text}} </b>
                  </div>
                </template>
                <b-form-radio-group
                  name="cats"
                  class="categories-radio-group "
                  stacked
                  :options="category.subcategory"
                  v-model="selectedCategory.idSubCategory"
                  v-if="selectedCategory"
                  :disabled="isloadingProducts"
                  @change="getProductsBySubCategory"

                />
              </app-collapse-item>
            </app-collapse>
            <div v-if="categories.categoryServices.length" class="brands">
                <h6 class="filter-title" :class="colorFilter">
                  <feather-icon
                    icon="PackageIcon"
                    size="16"
                  />  Servicios
                </h6>
                <b-form-radio-group
                  name="cats"
                  v-model="selectedCategory.value"
                  class="brands-radio-group ml-1"
                  stacked
                  :disabled="isloadingProducts"
                  :options="categories.categoryServices"
                  @change="getProducts"
                />
              </div>

          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FiltroSidebarBooking from '@/modules/shop/components/experiences/FiltroSidebarBooking'
import * as moment from 'moment'  
import {
  adultOptions,
  childrenOptions,
} from "@/data/data/";
//seeds data
import { toJson } from '@/helpers/helpers'
import flatPickr from 'vue-flatpickr-component'
export default {
  async created() {

  },
  components:{
    flatPickr,
    FiltroSidebarBooking,
    AppCollapse,AppCollapseItem,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dateRange:moment(new Date()).format("YYYY-MM-DD"),
      adults:1,
      children:0,
      adultOptions,
      childrenOptions,
      isload:false

    };
  }, 
  watch: {
   isLoadingCategories: {
      deep: true,
     async handler() {        
      await this.sendEXperience( this.selectedCategory)
      },
    },
  },
  computed:{
    ...mapState('appConfig',['layout']),
    ...mapState('start',['categories', 'isLoadingCategories']),
    ...mapState('products',['isloadingProducts','selectedCategory']),
    ...mapState('start',['queryParams']),
    colorFilter(){
      return this.layout.skin === 'dark' ? 'title-filter-dark' : 'title-filter-light'
    },

  },
  methods:{
    ...mapMutations('start',['setParamsRates']),
    ...mapActions('products',['fetchProducts','fetchProductsByCategories','fetchProductsRate','fetchProductsBySubCategory','fetchPackages','fetchEvents']),
    ...mapMutations('products',['setProducts','setSelectedCategory','setSelectedExperienceDate']),
    
    async getProducts(valueCategory){
      let products = []
      const { onlyCategories, hotelServices, categoryServices, hotelServicesWithSubCategories } = this.categories
      const myCategories =  [...onlyCategories, ...hotelServices, ...categoryServices, ...hotelServicesWithSubCategories ]
      
      const categoria = toJson(myCategories.find( cat => cat.value === valueCategory ) ) //category para la peticion
      categoria.idCategory = categoria.value
      categoria.idSubCategory = null
      const { action } = categoria

 
      if( action == 'get-products-best-seller' ) products = await this.sendEXperience(categoria)
      if( action == 'get-products-by-category') products = await this.sendEXperience( categoria )
      if( action == 'get-packages' ) products = await this.fetchPackages()
      if( action == 'get-events' ) products = await this.fetchEvents()
   
      this.setSelectedCategory( categoria )
      this.setProducts( products )
      this.$emit('set-current-page')
    },
    async getProductsBySubCategory(idSubCategory){
      let products = []
      const { withSubCategories, hotelServicesWithSubCategories } = this.categories
      const normalService = withSubCategories.find(category => category?.subcategory?.find(subCat => subCat?.value === idSubCategory ))
      const hotelService = hotelServicesWithSubCategories.find(category => category?.subcategory?.find(subCat => subCat?.value === idSubCategory ))
      const categoria = toJson( normalService || hotelService) //category para la peticion
      categoria.idSubCategory = idSubCategory
      categoria.idCategory = null
      delete categoria.subcategory
      products = await this.sendEXperience( categoria )
      this.setSelectedCategory( categoria )
      this.setProducts(products)
      this.$emit('set-current-page')
    },
    collapseOpen( category){
      const idSubCategory = this.selectedCategory.idSubCategory
      if( idSubCategory ){     //si encuentra registro regresará un true
        const cat = category.subcategory.find(subCat => subCat.value === idSubCategory )
        return cat ? true : false
      }
      if(!idSubCategory){
        return false
      }
    },

    async sendEXperience(categories){
      let products
      let selectedCategory= categories  ? categories.action : categories
      // console.log('Se metió por aquí: sendEXperience');
      if( selectedCategory == 'get-packages' ){
        products = await this.fetchPackages()
        this.setProducts(products)
        return false
      }

      if( selectedCategory == 'get-events' ){
        products = await this.fetchEvents()
        this.setProducts(products)
        return false
      }

      const operationDate=this.dateRange
      this.setSelectedExperienceDate(operationDate)
      let category= this.selectedCategory ? this.selectedCategory.idCategory : null
      if (!category) category="BestSeller-01"
      if(categories){
        category=categories.idCategory
        this.setSelectedCategory( categories )
      }

      const payloadParamsRates = {
        dateRange:this.queryParams.dateRange||operationDate,
        adults:this.queryParams.adults,
        children:this.queryParams.children,
        idCat: category !="BestSeller-01" ? category: null,
        idsub: this.selectedCategory ? this.selectedCategory.idSubCategory : null,
      }

      this.setParamsRates(payloadParamsRates)
      products = await this.fetchProductsRate(payloadParamsRates)
      this.setProducts(products)

      this.$emit('set-current-page')
      return products
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.title-filter-dark {
  color: white;
  font-weight: bold;
}

.title-filter-light {
  color: black;
  font-weight: bold;
}

</style>
