<template>
  <div style="height: inherit">    
    <Header
      :mqShallShowLeftSidebar="mqShallShowLeftSidebar"
      :sortBy="sortBy"
      :sortByOptions="sortByOptions"
      :itemView="itemView"
      :itemViewOptions="itemViewOptions"
      @change-view="changeView"
      @sort-by-option="sortByOption"
      @mq-show-sidebar-left="mQShowSidebarLeft"
      @set-current-page="setCurrentPage"
    />

    <!-- Overlay -->
    <div class="body-content-overlay" />
   
    <!-- Products -->
    <ExperiencesList :itemView="itemView" :filters="filters" class="mt-1" />
    <!-- Pagination of products -->
   
    <ProductsPagination
      :totalRows="filteredProducts.length"
      :filters="filters"
      v-if="filteredProducts.length > 10 && !isloadingProducts"
    />

    <portal to="content-renderer-sidebar-detached-left">
      <FiltroSidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @set-current-page="setCurrentPage"
      />
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Header from '@/modules/shop/components/experiences/Header'
import FiltroSidebar from '@/modules/shop/components/experiences/FiltroSidebar'
import ExperiencesList from '@/modules/shop/components/experiences/ExperiencesList'
import ProductsPagination from '@/modules/shop/components/experiences/ProductsPagination'
import { market } from '@/modules/shop/mixins/market'

import { itemViewOptions, sortByOptions, filterOptions, filters, } from '@/data/data'
import { toJson } from '@/helpers/helpers';

export default {
  mixins: [ market ],
  async mounted() {      
    if (this.filteredProducts.length == 0) {
      await this.getInitialContent()
    }
    if ( this.categoriesIsEmpty() ) {// categoriesIsEmpty booleano desde el mixin market
      await this.getCategoriesAndCurrencies()
    }
    this.setCategoryInPage()
    this.setCurrencyCustomer()
  },
  components: {
    Header,
    FiltroSidebar,    
    ExperiencesList,
    ProductsPagination,
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      itemView: "grid-view",
      itemViewOptions,
      sortBy: { text: "Orden alfabético", value: "alfabético" },
      sortByOptions,
      filters,
      filterOptions,
      mqShallShowLeftSidebar: false,
    };
  },
  computed: {
    ...mapState('shop', ['cart']),    
    ...mapState('start', ['categories', 'currencies']),
    ...mapState('products', ['selectedCategory','isloadingProducts']),       
    ...mapGetters('products', ['filteredProducts']),    
  },
  methods: {
    ...mapActions('products', ['fetchProducts', 'getInitialContent', 'getCategoriesAndCurrencies']),
    ...mapMutations('products', ['setSortBy','setSelectedCategory']),    
    ...mapMutations('shop', ['setCurrency']),

    changeView(viewType) {
      this.itemView = viewType;
    },
    sortByOption(option) {
      this.sortBy = option;
      this.setSortBy(JSON.parse(JSON.stringify(this.sortBy)));
    },
    mQShowSidebarLeft(booleano) {
      this.mqShallShowLeftSidebar = booleano;
    },
    setCurrentPage() {
      this.filters.currentPage = 1
    },
    
    setCategoryInPage(){     
      if(!this.selectedCategory){
        this.setSelectedCategory({section: "Categorías", action: "get-products-best-seller", text: "Best Seller", value: "BestSeller-01", idCategory: 'BestSeller-01', idSubCategory: null})        
      }
    },
    setCurrencyCustomer() {
      const cliente = this.cart.cliente;
      const { currency } = cliente;
      const divisa = toJson(
        this.currencies.find((currency) => currency.code === "USD")
      );
      if (!currency) {
        this.setCurrency(divisa);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>